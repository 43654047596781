<template>
  <Map ref="map"/>
</template>

<script>
import Map from '@/components/map/Map.vue'
import {HELPER_FUNCTIONS, ROUTE_PLAN_CALCULATOR, TRACK_CEIL} from "@/plugins/utils";

export default {
  name: 'FlightObserverView',
  components: {Map},
  data() {
    return {
      routeId: null,
      routePlan: null,
      routePlanChanges: [],
    };
  },
  props: {
    app: Object
  },
  methods: {
    initMapDrawAndElements() {
      this.$refs.map.clearPlanElements();
      this.routePlan.routePlanElements.forEach((element, index) => {
				const startLatLng = HELPER_FUNCTIONS.getStartLatLong(element);
				const endLatLng = HELPER_FUNCTIONS.getEndLatLong(element);

        if (index === 0) {
          this.$refs.map.drawMarker(startLatLng, true, {
            title: this.routePlan.startElementTitle,
          });
        }

        this.$refs.map.drawMarker(endLatLng, false, {
          title: element.title,
        });
        this.$refs.map.drawLine([startLatLng, endLatLng], '#00ffe1', TRACK_CEIL(element.magneticTrack).toString());
      });

    },
		async fetchCoordinates() {
			let page = 0;
			let pageSize = 100;

			let firstResp = await this.$rest.getCoordinates(this.routeId, page++, pageSize);
			if(firstResp == null) return false;
			const { maxPage, routePlan, routePlanChanges, coordinates } = firstResp

			this.$refs.map.addRoute(firstResp);
			if (routePlan) {
				this.routePlan = routePlan;
				this.initMapDrawAndElements();
			}

			for (const change of (routePlanChanges || [])) {
				const trueTrack = ROUTE_PLAN_CALCULATOR.TRUE_TRACK(change.currentLatitude, change.currentLongitude, change.latitude, change.longitude)
				const magneticTrack = ROUTE_PLAN_CALCULATOR.MAGNETIC_TRACK(trueTrack, ROUTE_PLAN_CALCULATOR.DEFAULT_DECLINATION);
				this.$refs.map.drawLine([[change.currentLatitude, change.currentLongitude], [change.latitude, change.longitude]], '#e90383', TRACK_CEIL(magneticTrack).toString());
			}


			let more = (await Promise.all(window.range(page, maxPage + 1).map(async i => {
				return await this.$rest.getCoordinates(this.routeId, i, pageSize);
			}))).filter(r => r).map(resp => resp.coordinates);

			if(more.length > 0){
				this.$refs.map.updateRouteWithNewCoordinates(this.routeId, more.reduce((a, b) => a.concat(b), []));
			}

		}
  },
  async mounted() {
		this.routeId = this.$route.params.id;

		const route = await this.$rest.getRoute(this.routeId);
		if(route == null) {
				await this.$router.replace({name: 'flights'});
				return;
		}

		await this.fetchCoordinates();

		this.$refs.map.setRoute(route);
		this.$refs.map.alwaysShowRouteButtons = true;

		setTimeout(() => {
			this.$refs.map.moveToRoute(this.routeId);
		}, 300);


		this.$refs.map.openWebSocket(this.$rest, () => {
			this.$refs.map.registerToRouteUpdates(this.routeId);
			this.$refs.map.registerToFlyingObjectUpdates(route.registrationNumber, true);
		});
  }
}
</script>

<style>
</style>
